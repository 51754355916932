import { Injectable, inject } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ToasterService } from '../../core/services/toaster.service';
import { LoaderService } from '../../core/services/loader.service';
import { ApiConstant } from '../../core/constants/api-constants';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsonEditorService {
  selectedJson:any;
  constructor(
    private apiService: ApiService,
    private toasterService: ToasterService,
    private loaderService: LoaderService
  ) { }

  setJsonOptions(jsonObj: any) {
    const jsonArray: any = [];
    for (const key in jsonObj) {
      if (Object.prototype.hasOwnProperty.call(jsonObj, key)) {
        const element = jsonObj[key];
        const data = {
          key: key.split('_').join(' '),
          value: key
        };
        jsonArray.push(data);
      }
    }
    return jsonArray;
  }

  saveJson(json: string) {
    const payload = {
      perma:this.selectedJson.value,
      json_data:JSON.parse(json)
    }
    this.loaderService.displayLoader(true);
    const endpoint = this.apiService.getApiUrl(ApiConstant.JSONEDITOR.UPDATEJSON)
    this.apiService.ExecutePut(endpoint, payload).subscribe({
      next: (response: any) => {
        this.loaderService.displayLoader(false);
        if (response.success) {
          this.toasterService.alert.toast({
            type: 'success',
            message: response.message
          })
        } else {
          this.toasterService.alert.toast({
            type: 'error',
            message: response.message
          })
        }
      },
      error: (error) => {
        this.toasterService.alert.toast({
          type: 'error',
          message: error.message
        });
        this.loaderService.displayLoader(false);
      }
    })
  }
}

export const editorGuard: CanActivateFn = (route, state) => {
  const requestedRoute = route.routeConfig?.path;
  if (!environment.production) {
    return true;
  } else {
    inject(Router).navigateByUrl('access-denied');
    return false;
  }
};